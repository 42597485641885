@if (isEnvironmentBannerVisible) {
  <div class="banner banner--{{ bannerClassModifier }}">
    <span class="banner__text">
      Environment: {{ environmentTitle }}, Version: {{ staticVersion }}, Build: {{ staticBuildNumber }}
      @if ((userTimeZone$ | async); as userTimeZone) {, User TZ: {{ userTimeZone }} }
      @if (databaseName) {, Database: {{ databaseName }}  }
  </span>
</div>
}
<div class="app-ui"
  [class.app--is-nav-open]="primaryNavOpen"
  [class.app--has-sidebar]="layoutService.sidebarPortal$ | async"
  [class.app--has-embedded-sidebar]="layoutService.hasEmbeddedSidebar"
  [class.app--has-navbar]="layoutService.navbarPortal$ | async"
  >
  <div class="app-split-area">
    <div class="split-wrap">
      <div class="app__primary-nav">
        <collapp-primary-nav [open]="primaryNavOpen" (toggle)="onPrimaryNavOpenToggle()"></collapp-primary-nav>
      </div>
      @if (layoutService.sidebarPortal$ | async; as sidebarPortal) {
        <div class="app__sidebar">
          <div class="app__sidebar-content"><ng-container [cdkPortalOutlet]="sidebarPortal" (attached)="layoutService.onSidebarPortalAttached($event)"></ng-container></div>
        </div>
      }
      @if (layoutService.navbarPortal$ | async; as navbarPortal) {
        <div class="app__navbar">
          <div class="stage__navbar-content">
            <ng-container [cdkPortalOutlet]="navbarPortal" (attached)="layoutService.onNavbarPortalAttached($event)"></ng-container>
          </div>
        </div>
      }
    </div>
  </div>
  <main class="app-main">
    <div class="stage">
      @if (layoutService.asidePortal$ | async; as asidePortal) {
        <div class="stage__aside">
          <div class="stage__aside-content"
            ><ng-container [cdkPortalOutlet]="asidePortal" (attached)="layoutService.onAsidePortalAttached($event)"></ng-container
          ></div>
        </div>
      }
      <div class="stage__outlet">
        <div class="stage__outlet-content">
          <router-outlet></router-outlet
          ></div>
        </div>
      </div>
    </main>
    <ngx-toasty [position]="'top-right'"></ngx-toasty>
  </div>
  @if (isLoadingIndicatorVisible) {
    <div class="app-loader-container">
      <div class="app-loader-container__background"></div>
      <div class="app-loader-container__content">
        <collapp-loading-spinner></collapp-loading-spinner>
      </div>
    </div>
  }
