import { Injectable } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map, mapTo } from 'rxjs/operators';
import { CollAppApiService } from './collapp-api.service';
import { CountryDtoModel } from '../models/dtos/country.dto.model';
import { GateDtoModel } from '../models/dtos/gate.dto.model';
import { UnitDto } from '../interfaces/dtos/unit.dto';
import { UnitDtoModel } from '../models/dtos/unit.dto.model';
import { UnitListDtoModel } from '../models/dtos/unit-list.dto.model';
import { CountryListResponse } from '../interfaces/responses/country-list.response';
import { GateListResponse } from '../interfaces/responses/gate-list.response';
import { UnitListResponse } from '../interfaces/responses/unit-list.response';
import { RoleListResponse } from '../interfaces/responses/role-list.response';
import { RoleListDtoModel } from '../models/dtos/role-list.dto.model';
import { ProjectTypeListResponse } from '../interfaces/responses/project-type-list.response';
import { ProjectTypeListResponseModel } from '../models/responses/project-type-list.response.model';
import { ProjectTypeDtoModel } from '../models/dtos/project-type.dto.model';
import { RatingListResponseModel } from '../models/responses/rating-list.response.model';
import { RatingDtoModel } from '../models/dtos/rating.dto.model';
import { RatingListResponse } from '../interfaces/responses/rating-list.response';
import { TimeZoneDto } from '../interfaces/dtos/time-zone.dto';
import { TimeZoneDtoModel } from '../models/dtos/time-zone.dto.model';
import { CountryListResponseModel } from '../models/responses/country-list.response.model';
import { GateListResponseModel } from '../models/responses/gate-list.response.model';
import { RoleListResponseModel } from '../models/responses/role-list.response.model';
import { ResourceLinkDtoModel } from '../models/dtos/resource-link.dto.model';
import { ResourceLinkDto } from '../interfaces/dtos/resource-link.dto';
import { UserPreferencesUpdateRequestModel } from '../models/requests/user-preferences-update.request.model';
import { UserCalendarLinksResponse } from '../interfaces/responses/user-calendar-links-response';
import { UserCalendarLinksResponseModel } from '../models/responses/user-calendar-links-response.model';
import { CostObjectDtoModel } from '../models/dtos/costObject.dto.model';
import { CostObjectDto } from '../interfaces/dtos/cost-object.dto';

@Injectable({
  providedIn: 'root',
})
export class BaseDataService {
  constructor(
    private apiService: CollAppApiService,
  ) { }

  /**
   * Gets a list of all countries
   *
   * @return {Observable<CountryDtoModel[]>}
   */
  getAllCountries$(): Observable<CountryDtoModel[]> {
    return this.apiService
      .getCountries$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<CountryListResponse>) => CountryListResponseModel.fromJSON(response.body!)),
        map((response) => response.items.slice(0)),
      );
  }

  /**
   * Gets a list of all links
   *
   * @return {Observable<ResourceLinkDtoModel[]>}
   */
  getAllLinks$(): Observable<ResourceLinkDtoModel[]> {
    return this.apiService
      .getLinks$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ResourceLinkDto[]>) => response.body!
          .map((link) => ResourceLinkDtoModel.fromJSON(link))),
        map((response) => response.slice(0)),
      );
  }

  /**
   * Gets a list of all ratings
   */
  getAllRatings$(): Observable<readonly RatingDtoModel[]> {
    return this.apiService
      .getRatings$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<RatingListResponse>) => RatingListResponseModel.fromJSON(response.body!)),
        map((response) => response.items),
      );
  }

  /**
   * Gets a list of all gates
   *
   * @return {Observable<GateDtoModel[]>}
   */
  getAllGates$(): Observable<GateDtoModel[]> {
    return this.apiService
      .getGates$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<GateListResponse>) => GateListResponseModel.fromJSON(response.body!)),
        map((response) => response.items.slice(0)),
      );
  }

  /**
   * Gets a list of all project types
   */
  getAllProjectTypes$(): Observable<readonly ProjectTypeDtoModel[]> {
    return this.apiService
      .getProjectTypes$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ProjectTypeListResponse>) => ProjectTypeListResponseModel.fromJSON(response.body!)),
        map((response) => response.items),
      );
  }

  /**
   * Gets a list of all units
   *
   * @return {Observable<UnitListDtoModel[]>}
   */
  getAllUnits$(): Observable<UnitListDtoModel[]> {
    return this.apiService
      .getUnits$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UnitListResponse>) => response.body!
          .items
          .map((unit) => UnitListDtoModel.fromJSON(unit))),
      );
  }

  /**
   * Gets a single units by units id
   *
   * @param {string} unitId
   * @return {Observable<UnitDtoModel>}
   */
  getUnitByUnitId$(unitId: number): Observable<UnitDtoModel> {
    return this.apiService
      .getUnitByUnitId$(unitId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UnitDto>) => UnitDtoModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets a list of all roles
   */
  getAllRoles$(): Observable<readonly RoleListDtoModel[]> {
    return this.apiService
      .getRoles$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<RoleListResponse>) => RoleListResponseModel.fromJSON(response.body!)),
        map((response) => response.items),
      );
  }

  /**
   * Gets the time zone list.
   */
  getTimeZones$(): Observable<TimeZoneDtoModel[]> {
    return this.apiService
      .getTimeZones$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<TimeZoneDto[]>) => (Array.isArray(response.body) ? response.body : [])
          .map((item) => TimeZoneDtoModel.fromJSON(item))),
      );
  }

  getCalendarLinks$(): Observable<UserCalendarLinksResponseModel | null> {
    return this.apiService
      .getCalendarLinks$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UserCalendarLinksResponse>) => (response != null
          ? UserCalendarLinksResponseModel.fromJSON(response.body!)
          : null)),
      );
  }

  putUserPreferences$(timeZone: string, displayInLocalLanguage: boolean): Observable<void> {
    const request = new UserPreferencesUpdateRequestModel(
      timeZone,
      displayInLocalLanguage,
    );

    return this.apiService
      .putUserPreferences$(request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Gets the currently used database server name
   *
   * @return {Observable<string>}
   */
  getDatabaseName$(): Observable<string> {
    return this.apiService
      .getDatabaseName$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<string>) => response.body!),
    );
  }
}
