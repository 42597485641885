import {
  ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation,
} from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable, Subject, timer } from 'rxjs';
import {
  distinctUntilChanged,
  filter, map, mapTo, mergeMap, share, switchMap, takeUntil, tap,
} from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import moment from 'moment';

import { MOBILE } from './services/constants';

import { AccessControlService, AuthenticationError, AuthorizationCheckError } from './services/access-control.service';
import { LayoutService } from './services/layout.service';
import { ApplicationInsightsService } from './modules/application-insights';
import { LoadingIndicatorService } from './services/loading-indicator.service';
import { UserSelectors } from './state';
import { BrowserDetectionService } from './services/browser-detection.service';
import { TimeZoneService } from './services/time-zone.service';
import { environment } from '../environments/environment';
import { ToastyService } from './shared/toasty';
import { MomentPipe } from './collapp-common/pipes';
import { TranslateService } from '@ngx-translate/core';
import { BaseDataService } from './api/services/base-data.service';

@Component({
  selector: 'collapp-root',
  styleUrls: ['../styles/main.scss', './app.component.scss'],
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  static version: string = APP_VERSION;

  static buildNumber: string = BUILD_NUMBER;

  @Select(UserSelectors.userTimeZone)
  userTimeZone$!: Observable<string | null>;

  @HostBinding('class.app--has-environment-banner')
  hostClass: boolean = environment.showBanner;

  readonly isEnvironmentBannerVisible: boolean = environment.showBanner;

  readonly environmentTitle: string = environment.title;

  get staticVersion(): string { return AppComponent.version; }

  get staticBuildNumber(): string { return AppComponent.buildNumber; }

  databaseName!: string | null;

  mobile: boolean = MOBILE;

  primaryNavOpen: boolean = false;

  readonly bannerClassModifier: string;

  isLoadingIndicatorVisible: boolean = false;

  isLoadingRoute: boolean = false;

  isLoadingLazyRouteModule: boolean = false;

  routeLoadCount: number = 0;

  routeConfigLoadCount: number = 0;

  private validUntilNotificationReceived: boolean = false;

  private destroyed$: Subject<void> = new Subject();

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store,
    private accessControlService: AccessControlService,
    private appInsightsService: ApplicationInsightsService,
    private browserDetectionService: BrowserDetectionService,
    public layoutService: LayoutService,
    public router: Router,
    private loadingService: LoadingIndicatorService,
    private titleService: Title,
    private toastyService: ToastyService,
    private momentPipe: MomentPipe,
    private timeZoneService: TimeZoneService,
    private translateService: TranslateService,
    private baseDataService: BaseDataService
  ) {
    (window as any).Logging = {};
    (window as any).Logging.level = (environment.msalLoggingEnabled ? 3 : -1);
    (window as any).Logging.piiLoggingEnabled = environment.msalLoggingEnabled;
    (window as any).Logging.log = (): void => {
    // No msal log
    };

    accessControlService.events$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event) => {
        if (
          event instanceof AuthenticationError
        || event instanceof AuthorizationCheckError
        ) {
          appInsightsService.trackException({ exception: event.error });
        }
      });

    this.clearStorageIfNewDeploy();
    accessControlService.start();

    this.bannerClassModifier = environment.title
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '-');

    translateService.addLangs(['en']);
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  // eslint-disable-next-line max-lines-per-function
  ngOnInit(): void {
    if (window.console) {
      const memes = [
        '         ▄              ▄\n        ▌▒█           ▄▀▒▌\n        ▌▒▒█        ▄▀▒▒▒▐\n       ▐▄▀▒▒▀▀▀▀▄▄▄▀▒▒▒▒▒▐\n     ▄▄▀▒░▒▒▒▒▒▒▒▒▒█▒▒▄█▒▐\n   ▄▀▒▒▒░░░▒▒▒░░░▒▒▒▀██▀▒▌\n  ▐▒▒▒▄▄▒▒▒▒░░░▒▒▒▒▒▒▒▀▄▒▒▌\n  ▌░░▌█▀▒▒▒▒▒▄▀█▄▒▒▒▒▒▒▒█▒▐\n ▐░░░▒▒▒▒▒▒▒▒▌██▀▒▒░░░▒▒▒▀▄▌\n ▌░▒▄██▄▒▒▒▒▒▒▒▒▒░░░░░░▒▒▒▒▌\n▌▒▀▐▄█▄█▌▄░▀▒▒░░░░░░░░░░▒▒▒▐\n▐▒▒▐▀▐▀▒░▄▄▒▄▒▒▒▒▒▒░▒░▒░▒▒▒▒▌\n▐▒▒▒▀▀▄▄▒▒▒▄▒▒▒▒▒▒▒▒░▒░▒░▒▒▐\n ▌▒▒▒▒▒▒▀▀▀▒▒▒▒▒▒░▒░▒░▒░▒▒▒▌\n ▐▒▒▒▒▒▒▒▒▒▒▒▒▒▒░▒░▒░▒▒▄▒▒▐\n  ▀▄▒▒▒▒▒▒▒▒▒▒▒░▒░▒░▒▄▒▒▒▒▌\n    ▀▄▒▒▒▒▒▒▒▒▒▒▄▄▄▀▒▒▒▒▄▀\n      ▀▄▄▄▄▄▄▀▀▀▒▒▒▒▒▄▄▀\n         ▒▒▒▒▒▒▒▒▒▒▀▀',
        '⣿⣿⣿⣿⣿⡏⠉⠛⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⣿ \n⣿⣿⣿⣿⣿⣿⠀⠀⠀⠈⠛⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠿⠛⠉⠁⠀⣿ \n⣿⣿⣿⣿⣿⣿⣧⡀⠀⠀⠀⠀⠙⠿⠿⠿⠻⠿⠿⠟⠿⠛⠉⠀⠀⠀⠀⠀⣸⣿ \n⣿⣿⣿⣿⣿⣿⣿⣷⣄⠀⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣴⣿⣿ \n⣿⣿⣿⣿⣿⣿⣿⣿⣿⠏⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠠⣴⣿⣿⣿⣿ \n⣿⣿⣿⣿⣿⣿⣿⣿⡟⠀⠀⢰⣹⡆⠀⠀⠀⠀⠀⠀⣭⣷⠀⠀⠀⠸⣿⣿⣿⣿ \n⣿⣿⣿⣿⣿⣿⣿⣿⠃⠀⠀⠈⠉⠀⠀⠤⠄⠀⠀⠀⠉⠁⠀⠀⠀⠀⢿⣿⣿⣿ \n⣿⣿⣿⣿⣿⣿⣿⣿⢾⣿⣷⠀⠀⠀⠀⡠⠤⢄⠀⠀⠀⠠⣿⣿⣷⠀⢸⣿⣿⣿ \n⣿⣿⣿⣿⣿⣿⣿⣿⡀⠉⠀⠀⠀⠀⠀⢄⠀⢀⠀⠀⠀⠀⠉⠉⠁⠀⠀⣿⣿⣿ \n⣿⣿⣿⣿⣿⣿⣿⣿⣧⠀⠀⠀⠀⠀⠀⠀⠈⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢹⣿⣿ \n⣿⣿⣿⣿⣿⣿⣿⣿⣿⠃⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⣿⣿',
        '░░░░░░░░░░▄▄█▀▀▀▀▀▀▀▀█▄▄░░░░░░░░\n░░░░░░░▄▄▀▀░░░░░░░░░░░░▀▀▄▄░░░░░\n░░░░░▄█▀░░░░▄▀░░░░▄░░░░░░░▀█░░░░\n░░░░██▄▄████░░░░░░▀▄░░░░░░░░█▄░░\n░░▄████▀███▄▀▄░░░░░░███▄▄▄▄░░█░░\n░▄█████▄████░██░░░▄███▄▄░▀█▀░░█░\n▄███████▀▀░█░▄█░▄███▀█████░█░░▀▄\n█░█▀██▄▄▄▄█▀░█▀█▀██████▀░██▀█░░█\n█░█░▀▀▀▀▀░░░█▀░█░███▀▀░░▄█▀░█░░█\n█░░█▄░░░░▄▄▀░░░█░▀██▄▄▄██▀░░█▄░█\n█░░░░▀█▀▀▀░░░░░░█░░▀▀▀▀░░░░▄█░░█\n█░░░░░░░░░░░░░░░░▀▄░░░░░░▄█▀░░░█\n░█░░░░░░░░░░░░░░░░▀▀▀▀▀▀▀▄░░░░█░\n░░█░░░░░░▄▄▄▄▄▄▄░░░░░░░░░░░░░▄▀░\n░░░▀▄░░░░░▀█▄░░░▀▀██▄░░░░░░░▄▀░░\n░░░░░▀▄▄░░░░░▀▀▀▀▀░░░░░░░░▄▀░░░░\n░░░░░░░░▀▀▄▄▄░░░░░░░░▄▄▄▀▀█░░░░░\n░░░░░░░░░░▄▀▀█████▀▀▀▀░░░░██░░░░\n░░░░░░░░░█░░░██░░░█▀▀▀▀▀▀▀▀█░░░░',
      ];

      const devMessages: string[] = [];

      const versionAndBuild = `Version ${AppComponent.version} | Build ${AppComponent.buildNumber}`;
      const randomMeme = memes[Math.floor(Math.random() * memes.length)];
      const randomDevMessage = devMessages[Math.floor(Math.random() * devMessages.length)];
      const url = 'https://yoo.digital';

      if (!this.browserDetectionService.isIEOrEdge()) {
        const args = [
          `\n%cCollApp\n\n%c${versionAndBuild}\n\nMade with %c♥%c | ${url}\n${randomDevMessage ? `\n${randomDevMessage}\n` : ''}\n%c${randomMeme}\n`,
          'color: #ff000f; font-weight: 700; font-size: 2em; text-shadow: 1px 1px 0px white, 1px -1px 0px white, -1px 1px 0px white, -1px -1px 0px white;',
          'color: #262626; background: #fff',
          'color: #ff2424; background: #fff',
          'color: #262626; background: #fff',
          'font-family: Times, "Courier New", Courier, monospace;',
        ];

        window.console.log(...args);
      } else {
        window.console.log(`\nCollApp\n${versionAndBuild}\nMade with ♥ | ${url}\n${randomDevMessage ? `\n${randomDevMessage}\n` : ''}\n${randomMeme}\n`);
      }
    }

    this.store
      .select(UserSelectors.user)
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe((user) => {
        if (user && user.userId) {
          this.appInsightsService.setAuthenticatedUserContext(user.userId);
        } else {
          this.appInsightsService.clearAuthenticatedUserContext();
        }

        this.timeZoneService.handleUserChange(user);
      });

    /**
     * Notifies the user one hour before his access to CollApp expires.
     *
     * @TODO Move to dedicated service and listen to user store changes.
     */
    this.store
      .select(UserSelectors.user)
      .pipe(
        map((user) => {
          if (user && user.validUntil) {
            return user.validUntil.clone();
          }

          return null;
        }),
        tap((validUntil) => {
          if (!validUntil) {
            // TODO Reset on user change. This is a dirty hack.
            this.validUntilNotificationReceived = false;
          }
        }),
        switchMap((validUntil) => timer(0, 60 * 1000)
          .pipe(
            mapTo(validUntil),
          )),
        filter((validUntil) => !this.validUntilNotificationReceived && validUntil != null),
        takeUntil(this.destroyed$),
      )
      .subscribe((validUntil: moment.Moment) => {
        const now = moment();
        const diff = validUntil.diff(now);
        const oneHour = 60 * 60 * 1000;
        if (diff < oneHour) {
          this.validUntilNotificationReceived = true;
          this.toastyService.warning({
            title: 'Access about to expire',
            msg: `Your access to CollApp is about to expire ${validUntil.fromNow()} on ${this.momentPipe.transform(validUntil, 'datetime')}. Please save all your work in progress.`,
          });
        }
      });

    this.router
      .events
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe((event) => {
        // Assume values changed
        let hasChanges: boolean = true;

        if (event instanceof RouteConfigLoadStart) {
          this.routeConfigLoadCount += 1;
        } else if (event instanceof RouteConfigLoadEnd) {
          this.routeConfigLoadCount -= 1;
        } else if (event instanceof NavigationStart) {
          this.routeLoadCount += 1;
        } else if (
          event instanceof NavigationEnd){
            this.routeLoadCount -= 1;
            // update / fetch currently used database (dev & stage)
            if (this.isEnvironmentBannerVisible) {
              this.baseDataService.getDatabaseName$().pipe(
                distinctUntilChanged()
              ).subscribe(value => {
                this.databaseName = value
              });
            }
          } else if (
           event instanceof NavigationCancel
          || event instanceof NavigationError
        ) {
          this.routeLoadCount -= 1;
        } else {
          // No event matched, so there were no value changes
          hasChanges = false;
        }

        if (hasChanges) {
          this.isLoadingRoute = (this.routeLoadCount > 0);
          this.isLoadingLazyRouteModule = (this.routeConfigLoadCount > 0);
          const wasLoadingIndicatorVisible = this.isLoadingIndicatorVisible;
          this.isLoadingIndicatorVisible = (this.isLoadingRoute || this.loadingService.isLoading);
          if (this.isLoadingIndicatorVisible !== wasLoadingIndicatorVisible) {
            this.cdRef.detectChanges();
          }
        }
      });

    this.loadingService
      .isLoading$
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe((isLoading) => {
        const wasLoadingIndicatorVisible = this.isLoadingIndicatorVisible;
        this.isLoadingIndicatorVisible = (isLoading || this.isLoadingRoute);
        if (this.isLoadingIndicatorVisible !== wasLoadingIndicatorVisible) {
          this.cdRef.detectChanges();
        }
      });

    this.loadingService
      .isGuardsCheckInProgress$
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe((isGuardsCheckInProgress) => {
        if (isGuardsCheckInProgress) {
          this.isLoadingIndicatorVisible = false;
        } else {
          this.isLoadingIndicatorVisible = (this.loadingService.isLoading || this.isLoadingRoute);
        }
      });

    const routerNavigationEnd$ = this.router
      .events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map((event) => {
          let route = this.router.routerState.root;
          let path = '';
          while (route.firstChild) {
            if (route.firstChild.routeConfig) {
              path += `/${route.firstChild.routeConfig.path}`;
            }
            route = route.firstChild;
          }

          return {
            urlAfterRedirects: event.urlAfterRedirects,
            route,
            path,
          };
        }),
        filter((info) => info.route.outlet === 'primary'),
        share(),
      );

    routerNavigationEnd$
      .pipe(
        map((info) => info.route),
        tap((route) => {
          let j = route.pathFromRoot.length;
          let contextFound = false;
          while (j > 0 && !contextFound) {
            j -= 1;
            const { routeConfig } = route.pathFromRoot[j];
            if (routeConfig) {
              // @TODO Refactor one https://github.com/angular/angular/issues/24069 is resolved
              // eslint-disable-next-line no-underscore-dangle
              const loadedConfig = (routeConfig as any)._loadedConfig as any;
              if (loadedConfig && loadedConfig.module) {
                this.layoutService.setContext(
                  loadedConfig.module.injector,
                );

                contextFound = true;
              }
            }
          }

          if (!contextFound) {
            this.layoutService.resetContext();
          }
        }),
        mergeMap((route) => route.data),
        takeUntil(this.destroyed$),
      )
      .subscribe((routeData: any) => {
        this.layoutService.setNavbarComponent(routeData.navbar);
        this.layoutService.setSidebarComponent(routeData.sidebar);
        this.layoutService.setAsideComponent(routeData.aside);
        this.layoutService.setEmbeddedSidebar(routeData.hasEmbeddedSidebar);

        const appTitle = this.translateService.instant('common.app-title');
        if (routeData.title) {
          this.titleService.setTitle(`${this.translateService.instant(routeData.title)} | ${appTitle}`);
        } else {
          this.titleService.setTitle(appTitle);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onPrimaryNavOpenToggle(): void {
    this.primaryNavOpen = !this.primaryNavOpen;
  }

  private clearStorageIfNewDeploy(): void {
    if (window.localStorage && window.sessionStorage) {
      const key = `${this.staticVersion}-${this.staticBuildNumber}`;

      if (window.localStorage.getItem(key) === null) {
        this.deleteAllCookies();
        window.sessionStorage.clear();
        window.localStorage.clear();
        window.localStorage.setItem(key, `${+new Date()}`);
        window.localStorage.setItem('version', `${this.staticVersion}`);
      }
    }
  }

  private deleteAllCookies(): void {
    const cookies = document.cookie.split(';');

    // eslint-disable-next-line no-restricted-syntax
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  }
}
